import Vue from 'vue';
import auth from '@/services/auth';
import { Ax } from '@/utils';
import {BudgetVariants, Comp, Department, Dict, Ead as EadNs, Ead, ModuleStore, Org, Report, Utils, Version, employeePositionMode} from './types';
import StEad = Dict.StEad;
import axios from "axios";
import TemplateKey = Utils.TemplateKey;
import type {BoolEx} from "@/modules/budget/staffing-table/reports/types";


// region Разное
export const paramsSerializer = (params: any): string => {
    if (!(params instanceof Object)) {
        throw new Error('"params" is not object');
    }

    const result = new URLSearchParams();
    Object.getOwnPropertyNames(params).forEach(key => {
        const value = params[key];
        if (Array.isArray(value)) {
            value.forEach(valueItem => result.append(key, valueItem));
        } else {
            result.append(key, String(value));
        }
    });
    return result.toString();
};

export const selectAllOnClick = (ev: unknown) => {
    if (ev instanceof Event) {
        const target = ev.target;
        if ((target instanceof HTMLInputElement) || (target instanceof HTMLTextAreaElement)) {
            setTimeout(() => {
                target.select();
            });
        }
    }
};

// region Работа с IStafftabAddAttrHolder
export const getFromStafftabAddAttrHolder = (source: Ead.EadD | undefined, type: StEad.AddAttrType): null | boolean | string | number | Date => {
    if (source === undefined) {
        return null
    }
    switch (type) {
        case 'BOOL':
            return source.valueBool;
        case 'DATE':
            if (source.valueDate === null) {
                return null;
            }
            return new Date(source.valueDate);
        case 'NORMATIVE':
            return source.valueNormRate;
        case 'NUMBER':
            return source.valueNumber;
        case 'SALARY_RATE':
            return source.valueSalaryRate;
        case 'TEXT':
            return source.valueText;
        case 'HOURS':
            return source.valueNumber;
        default:
            return null;
    }
};

export const getValueFromDropdown = (source: Ead.stEadDropdownValues, type: StEad.AddAttrType): null | boolean | number | Date => {
    if (source === undefined) {
        return null
    }
    switch (type) {
        case 'BOOL':
            return source.valueBool;
        case 'DATE':
            if (source.valueDate === null) {
                return null;}
            return new Date(source.valueDate);
        default:
            return source.valueNumber;
    }
};

export const setToStafftabAddAttrHolder = (target: Ead.EadD, type: StEad.AddAttrType, value: null | boolean | string | number | Date) => {
    switch (type) {
        case 'BOOL':
            if (typeof value === 'boolean') {
                target.valueBool = value;
            } else {
                target.valueBool = null;
            }
            break;
        case 'DATE':
            if (value instanceof Date) {
                target.valueDate = value.getTime();
            } else {
                target.valueDate = null;
            }
            break;
        case 'NORMATIVE':
            if (typeof value === 'number') {
                target.valueNormRate = value;
            } else {
                target.valueNormRate = null;
            }
            break;
        case 'NUMBER':
            if (typeof value === 'number') {
                target.valueNumber = value;
            } else {
                target.valueNumber = null;
            }
            break;
        case 'SALARY_RATE':
            if (typeof value === 'number') {
                target.valueSalaryRate = value;
            } else {
                target.valueSalaryRate = null;
            }
            break;
        case 'TEXT':
            if (typeof value === 'string') {
                target.valueText = value;
            } else {
                target.valueText = null;
            }
            break;
        case 'HOURS':
            if (typeof value === 'number') {
                target.valueNumber = value;
            } else {
                target.valueNumber = null;
            }
            break;
        default:
            break;
    }
}

export const stafftabAddAttrHolderHasDifference = <T extends Ead.EadD> (type: StEad.AddAttrType, holder1: T | null, holder2: T | null): boolean => {
    if (holder1 === null) {
        return (holder2 !== null);
    }
    if (holder2 === null) {
        return true;
    }

    switch (type) {
        case 'BOOL':
            return ((holder1.valueBool === true) !== (holder2.valueBool === true));
        case 'DATE':
            return (holder1.valueDate !== holder2.valueDate);
        case 'NORMATIVE':
            return (holder1.valueNormRate !== holder2.valueNormRate);
        case 'NUMBER':
            return (holder1.valueNumber !== holder2.valueNumber);
        case 'SALARY_RATE':
            return (holder1.valueSalaryRate !== holder2.valueSalaryRate);
        case 'TEXT':
            return (holder1.valueText !== holder2.valueText);
        case 'HOURS':
            return (holder1.valueNumber !== holder2.valueNumber);
        default:
            return false;
    }
};

/**
 * Подготовка адреса запроса на основе "базового адреса" и карты параметров запроса
 *
 * @param baseUrl "базовый" адрес
 * @param fillParamMap блок кода для заполнения карты параметров запроса
 *
 * @return готовый адрес запроса
 */
export const prepareUrl = (baseUrl: string, fillParamMap: (paramMap: Map<string, string>) => void): string => {
    const paramMap = new Map<string, string>();
    fillParamMap(paramMap);

    const resultParts = [baseUrl];
    let firstParam = true;
    paramMap.forEach((value, key) => {
        if (firstParam) {
            resultParts.push('?');
            firstParam = false;
        } else {
            resultParts.push('&');
        }

        resultParts.push(key, '=', value);
    });
    paramMap.clear();

    const result = resultParts.join('');
    resultParts.splice(0, resultParts.length);
    return result;
};
// endregion

/*** Абп - управление по образованию */
export const abpIsEducation: string = '261'


// region Ключи дополнительных атрибутов
// noinspection SpellCheckingInspection
export const eadBoolKeys: string[] = [
    'CHILD', 'CIVIL_CONTRACT',
    'ELIGIBLE_FOR_EPC',
    'FULL_TIME_STUDENT',
    'INVALID_3DEGREE',
    'INVALID',
    'MILITARY_EXCEPT_CONSCRIPTS', 'MOTHER_WITH_MANY_CHILDREN',
    'PREGNANT_CHILD_CARE',
    'RETIRED_JUDGE_LIFE_PAY', 'RETIREE_BY_AGE', 'RETIREE_BY_SENIORITY',
    'SCSaSTCRWUA', 'SOCIAL_WORKERS', 'SKILLED_WORKER', 'SPECIAL_AGENCY', 'STAFF_MEMBER'
];
Object.freeze(eadBoolKeys);

// noinspection SpellCheckingInspection
export const eadNumberKeys: string[] = [
    'AL_BY_PPRK_1193', 'AL_BY_PPRK_646',
    'AP_COMB_POS_TEMP_ABSENT',
    'AP_EMERGENCY_STANDBY',
    'AP_FMAaMCfMDaPUpM',
    'AP_HARD_DANGER_WORK', 'AP_HOLIDAY_WORK',
    'AP_MILITARY_RANK_SALARY',
    'AP_NIGHT_WORK',
    'AP_OVERTIME_PAY',
    'AP_PPRK_1193',
    'AP_SPECIAL_COND_OF_SERV', 'AP_SPECIAL_TITLE', 'AP_SPECIAL_WORK_CONDITIONS_TG', 'AP_SPECIAL_CONDITIONS_TG',
    'AP_TEMP_ABSENT_DUTY__SUM',
    'BF_ECO_DISASTER_HEALTH', 'BF_LSADSASPJRADETODMSC', 'BF_MOVEMENT_LIFTING',
    'CM_HARMFUL_HAZARDOUS_CONDITIONS', 'COMP_SPECIAL_WORK_CONDITIONS',
    'SEASONAL_WORKER'
];
Object.freeze(eadNumberKeys);

// noinspection SpellCheckingInspection
export const eadSalaryRateKeys: string[] = [
    'AL_BY_PPRK_1193_DO',
    'AL_SPECIAL_WORK_CONDITIONS', 'AL_SPECIAL_COND_OF_SERV', 'AL_SPECIAL_WORK_CONDITIONS_MVD',
    'AL_SENIORITY',
    'AL_GRADE_QUALIFICATION_MVD',
    'AP_PROF_EXCELLENCE', 'AP_PSYCHO_EMO_PHYSICAL_SR', 'AP_PPRK_1193_DO',
    'AP_CHAMPION_PREPARE', 'AP_CLASS_RANK_SALARY_RATE', 'AP_COMBINING_POSITIONS',
    'AP_ECO_DISASTER_WORKING_SALARY_RATE', 'AP_EDU_PROCESS_PROVISION',
    'AP_HARD_DANGER_WORK__SR',
    'AL_HQSRRMSCL',
    'AP_QUALIFICATION_LEVEL',
    'AP_SPECIAL_WORK_CONDITIONS_OS',
    'AL_TROOP',
    'AP_TEMP_ABSENT_DUTY',
    'AL_LAW_RESEARCH',
    'BF_WELLNESS',
    'PM_RURAL',
    'COMP_SPECIAL_WORK_CONDITIONS_SALARY'
];
Object.freeze(eadSalaryRateKeys);

export const eadNormativeKeys: string[] = [
    'AL_BY_PPRK_1193_BDO',
    'AL_CAR_WITH_TRAILER',
    'AL_HONORARY_TITLE', 'AL_GRADE_QUALIFICATION', 'AL_SPECIAL_WORK_CONDITIONS__BDO', 'AL_SPORT_TITLE',
    'AL_TEAM_LEADERSHIP',
    'AL_PROF_EXCELLENCE',
    'AL_GRADE',
    'AP_ACADEMIC_DEGREE',
    'AP_MEDICAL_CARE_IN_AREA',
    'AP_CHECK_NOTE_AND_WORKS', 'AP_CLASS_GROUP_LEADERSHIP', 'AP_CLASSROOM_MANAGEMENT', 'AP_COMBINING_POSITIONS__BDO', 'AP_SPECIAL_CONDITIONS', 'AP_PPRK_1193_BDO',
    'AP_DEPARTMENT_MANAGEMENT',
    'AP_ECO_DISASTER_LIVING_MRP', 'AP_HARMFUL_DANGER_WORK', 'AP_PSYCHO_EMO_PHYSICAL',
    'AP_HARD_DANGER_WORK__BDO',
    'AP_IN_DEPTH_TEACH',
    'AP_ORG_IND_TRAINING',
    'AP_RAD_RISK_LIVING_MRP', 'AP_RAD_RISK_WORKING_MRP',
    'AP_SPECIAL_WORK_CONDITIONS', 'AP_STATUS_SENIOR', 'AP_STATUS_MAIN', 'AP_SPECIAL_CONDITIONS_MVD_BDO',
];
Object.freeze(eadNormativeKeys);

// noinspection SpellCheckingInspection
export const individualEadKeys: string[] = ['AP_DEPARTMENT_MANAGEMENT', 'AL_LAW_RESEARCH', 'AL_HQSRRMSCL', 'AL_CAR_WITH_TRAILER', 'AP_MEDICAL_CARE_IN_AREA',
'AP_ORG_IND_TRAINING', 'AP_STATUS_MAIN', 'AL_SPECIAL_WORK_CONDITIONS_MVD', 'AL_GRADE_QUALIFICATION_MVD', 'AP_SPECIAL_CONDITIONS_MVD_BDO', 'SEASONAL_WORKER',
    'CIVIL_CONTRACT', 'ELIGIBLE_FOR_EPC', 'FULL_TIME_STUDENT', 'INVALID', 'INVALID_3DEGREE', 'MILITARY_EXCEPT_CONSCRIPTS', 'MOTHER_WITH_MANY_CHILDREN', 'PREGNANT_CHILD_CARE',
    'RETIRED_JUDGE_LIFE_PAY', 'RETIREE_BY_AGE', 'RETIREE_BY_SENIORITY', 'SCSaSTCRWUA', 'SOCIAL_WORKERS', 'SKILLED_WORKER', 'SPECIAL_AGENCY', 'STAFF_MEMBER',
    'NOT_ELIGIBLE_FOR_MEPC', 'CHILD', 'NON_STAFF_MEMBER', 'PREMIUM_FOR_ADMINISTRATIVE', 'EDUCATOR', 'PREMIUM_FOR_DISMISSED_AGS'];
Object.freeze(individualEadKeys);

export const hideEadKeys: string[] = ['SEASONAL_WORKER_END'];
Object.freeze(hideEadKeys);

export const eadMilitaryRankKeys: string[] = ['AP_CLASS_RANK_BY_DICT'];
Object.freeze(eadMilitaryRankKeys);

export const eadMilitaryTitleKeys: string[] = ['AP_SPECIAL_TITLE_BY_DICT'];
Object.freeze(eadMilitaryTitleKeys);

export const eadToTypeMap: Map<string, Dict.StEad.AddAttrType> = ((): Map<string, Dict.StEad.AddAttrType> => {
    const result = new Map<string, Dict.StEad.AddAttrType>();

    eadBoolKeys.forEach(key => {
        result.set(key, 'BOOL');
    });
    eadNumberKeys.forEach(key => {
        result.set(key, 'NUMBER');
    });
    eadSalaryRateKeys.forEach(key => {
        result.set(key, 'SALARY_RATE');
    });
    eadNormativeKeys.forEach(key => {
        result.set(key, 'NORMATIVE');
    });
    eadMilitaryRankKeys.forEach(key => {
        result.set(key, 'MILITARY_RANK');
    });
    eadMilitaryTitleKeys.forEach(key => {
        result.set(key, 'MILITARY_TITLE');
    });

    return result;
})();
Object.freeze(eadToTypeMap);

export const getEadType = (ead: Dict.StEad | null): Dict.StEad.AddAttrType => {
    if (ead === undefined || ead === null) {
        return 'TEXT';
    }
    return ead.attrType;
};

// ValidateKey :: Список ключей и значений, которые подлежат проверке
export const eadValidateKeysListMap = new Map<string, any>([
    ['AL_PROF_EXCELLENCE', { max: 60, min: null, error: 'rate_cannot_exceed_max' }],
    ['PM_RURAL', { max: null, min: 25, error: 'rate_cannot_exceed_min' }]
]);
// endregion

//  Список значений для доп.атрибутов - code -> values
export const eadDropdownValueMap: Map<string, Array<EadNs.stEadDropdownValues>> = new Map();

(() => {
         axios.get('/api/budget/staffing_table/config/ead-dropdown/values')
            .then(response => {

                const cachedEadDropdownValues = response.data as Array<EadNs.stEadDropdownValues>;
                for (const item of cachedEadDropdownValues) {
                    const eadCode = item.ead.code;
                    const itemList = eadDropdownValueMap.get(eadCode);
                    if (itemList) {
                        itemList.push(item);
                    } else {
                        eadDropdownValueMap.set(eadCode, [item]);
                    }
                }
            })
            .catch(error => {
                console.error('Failed to fetch cachedEadDropdownValues:', error);
                throw error;
            });

})();
// endregion

// region Списки значений для дополнительных атрибутов
// noinspection SpellCheckingInspection
export const eadToValueListMap: Map<string, Array<Comp.DropdownItemDef<null | boolean | string | number | Date>>> = new Map([
    ['AP_ACADEMIC_DEGREE', [
        { text: '', value: null },
        { text: '17', value: 17 },
        { text: '34', value: 34 }
    ]],

    ['AP_ECO_DISASTER_LIVING_MRP', [
        { text: '', value: null },
        { text: '1.5', value: 1.5 },
        { text: '1.3', value: 1.3 },
        { text: '1.2', value: 1.2 }
    ]],

    ['AP_HARMFUL_DANGER_WORK', [
        { text: '', value: null },
        { text: '30% от БДО', value: 30 },
        { text: '60% от БДО', value: 60 },
        { text: '100% от БДО', value: 100 }
    ]],

    ['AP_MEDICAL_CARE_IN_AREA', [
        { text: '', value: null },
        { text: '50% от БДО', value: 50 }
    ]],

    ['AP_ORG_IND_TRAINING', [
        { text: '', value: null },
        { text: '100% от БДО', value: 100 }
    ]],

    ['AP_RAD_RISK_LIVING_MRP', [ // 2.0, 1.75, 1.5, 1.25, 1.0
        { text: '', value: null },
        { text: '2.0', value: 2.0 },
        { text: '1.75', value: 1.75 },
        { text: '1.5', value: 1.5 },
        { text: '1.25', value: 1.25 },
        { text: '1', value: 1 }
    ]],

    ['AP_RAD_RISK_WORKING_MRP', [
        { text: '', value: null },
        { text: '2.0', value: 2.0 },
        { text: '1.75', value: 1.75 },
        { text: '1.5', value: 1.5 },
        { text: '1.25', value: 1.25 },
        { text: '1', value: 1 }
    ]],

    ['AP_ECO_DISASTER_WORKING_SALARY_RATE', [
        { text: '', value: null },
        { text: '50% от ДО', value: 50 },
        { text: '30% от ДО', value: 30 },
        { text: '20% от ДО', value: 20 }
    ]],

    ['AL_CAR_WITH_TRAILER', [
        { text: '', value: null },
        { text: '30% от БДО', value: 30 }
    ]],

    ['AL_HONORARY_TITLE', [
        { text: '', value: null },
        { text: '30% от БДО', value: 30 },
        { text: '50% от БДО', value: 50 }
    ]],

    ['AL_TEAM_LEADERSHIP', [
        { text: '', value: null },
        { text: '35% от БДО', value: 35 },
        { text: '20% от БДО', value: 20 }
    ]],

    ['AL_TROOP', [
        { text: '', value: null },
        { text: '10% от ДО ', value: 10 },
        { text: '15% от ДО ', value: 15 },
        { text: '20% от ДО ', value: 20 },
        { text: '30% от ДО ', value: 30 },
        { text: '40% от ДО ', value: 40 }
    ]],

    ['AP_CHECK_NOTE_AND_WORKS', [
        { text: '', value: null },
        { text: '40% от БДО', value: 40 },
        { text: '50% от БДО', value: 50 }
    ]],

    ['AP_CLASSROOM_MANAGEMENT', [
        { text: '', value: null },
        { text: '20% от БДО', value: 20 },
        { text: '25% от БДО', value: 25 },
        { text: '30% от БДО', value: 30 }
    ]],

    ['AP_CLASS_GROUP_LEADERSHIP', [
        { text: '', value: null },
        { text: '50% от БДО', value: 50 },
        { text: '60% от БДО', value: 60 }
    ]],

    ['AP_CLASS_RANK_SALARY_RATE', [
        { text: '', value: null },
        { text: '15% от ДО', value: 15 },
        { text: '17% от ДО', value: 17 },
        { text: '20% от ДО', value: 20 },
        { text: '22% от ДО', value: 22 },
        { text: '25% от ДО', value: 25 }
    ]],

    ['AP_STATUS_MAIN', [
        { text: '', value: null },
        { text: '30% от БДО', value: 30 }
    ]],

    ['AP_IN_DEPTH_TEACH', [
        { text: '', value: null },
        { text: '20% от БДО', value: 20 },
        { text: '40% от БДО', value: 40 }
    ]],

    ['AP_PSYCHO_EMO_PHYSICAL', [
        { text: '', value: null },
        { text: '40% от БДО', value: 40 },
        { text: '50% от БДО', value: 50 },
        { text: '80% от БДО', value: 80 },
        { text: '100% от БДО', value: 100 },
        { text: '120% от БДО', value: 120 },
        { text: '150% от БДО', value: 150 },
        { text: '200% от БДО', value: 200 }
    ]],

    ['AP_PSYCHO_EMO_PHYSICAL_SR', [
        { text: '', value: null },
        { text: '15% от ДО', value: 15 },
        { text: '20% от ДО', value: 20 },
        { text: '30% от ДО', value: 30 },
        { text: '35% от ДО', value: 35 },
        { text: '40% от ДО', value: 40 },
        { text: '60% от ДО', value: 60 }
    ]],

    ['AP_DEPARTMENT_MANAGEMENT', [
        { text: '', value: null },
        { text: '50% от БДО ', value: 50 }
    ]],

    ['AP_STATUS_SENIOR', [
        { text: '', value: null },
        { text: '25% от БДО ', value: 25 }
    ]],

    ['AP_QUALIFICATION_LEVEL', [
        { text: '', value: null },
        { text: '30% от ДО ', value: 30 },
        { text: '70% от ДО ', value: 70 },
        { text: '100% от ДО  ', value: 100 }
    ]],

    ['AL_SENIORITY', [
        { text: '', value: null },
        { text: '15% от ДО ', value: 15 },
        { text: '20% от ДО ', value: 20 },
        { text: '30% от ДО ', value: 30 },
        { text: '40% от ДО ', value: 40 },
        { text: '50% от ДО ', value: 50 }
    ]],

    ['AL_SPECIAL_WORK_CONDITIONS_MVD', [
        { text: '', value: null },
        { text: '3% от ДО ', value: 3 },
        { text: '8% от ДО ', value: 8 },
        { text: '10% от ДО ', value: 10 }
    ]],

    ['AL_SPECIAL_COND_OF_SERV', [
        { text: '', value: null },
        { text: '3% от ДО ', value: 3 },
        { text: '8% от ДО ', value: 8 },
        { text: '10% от ДО ', value: 10 }
    ]],

    ['AL_SPORT_TITLE', [
        { text: '', value: null },
        { text: '15% от БДО ', value: 15 },
        { text: '30% от БДО ', value: 30 }
    ]],


    ['AL_GRADE', [
        { text: '', value: null },
        { text: '20% от БДО ', value: 20 },
        { text: '30% от БДО ', value: 30 },
        { text: '40% от БДО ', value: 40 },
        { text: '60% от БДО ', value: 60 }
    ]],

    ['AL_GRADE_QUALIFICATION', [
        { text: '', value: null },
        { text: '20% от БДО ', value: 20 },
        { text: '35% от БДО ', value: 35 }
    ]],

    ['AL_GRADE_QUALIFICATION_MVD', [
        { text: '', value: null },
        { text: '10% от ДО для Специалист 2 класса', value: 10.0 },
        { text: '20% от ДО для Специалист 1 класса', value: 20.0 },
        { text: '30% от ДО для Специалист 1 класса-наставник', value: 30.0 }
    ]],

    ['AP_SPECIAL_CONDITIONS_MVD_BDO', [
        { text: '', value: null },
        { text: 'Рядовой', value: 0.617 },
        { text: 'Младший сержант', value: 0.848 },
        { text: 'Сержант', value: 0.933 },
        { text: 'Старший сержант', value: 1.027 },
        { text: 'Старшина', value: 1.129 },
        { text: 'Младший лейтенант', value: 1.728 },
        { text: 'Лейтенант, инспектор таможенной службы III ранга', value: 2.16 },
        { text: 'Старший лейтенант, инспектор таможенной службы II ранга', value: 2.377 },
        { text: 'Капитан, инспектор таможенной службы I ранга', value: 2.614 },
        { text: 'Майор, советник таможенной службы III ранга', value: 3.268 },
        { text: 'Подполковник, советник таможенной службы II ранга', value: 3.595 },
        { text: 'Полковник, советник таможенной службы I ранга', value: 3.954 },
    ]],

    ['AL_LAW_RESEARCH', [
        { text: '', value: null },
        { text: '100% от ДО ', value: 100 }
    ]],

    ['AL_HQSRRMSCL', [
        { text: '', value: null },
        { text: '100% от ДО ', value: 100 }
    ]],

        ['SEASONAL_WORKER', [
        { text: '', value: null },
        { text: 'Январь', value: 1 },
        { text: 'Февраль', value: 2 },
        { text: 'Март', value: 3 },
        { text: 'Апрель', value: 4 },
        { text: 'Май', value: 5 },
        { text: 'Июнь', value: 6 },
        { text: 'Июль', value: 7 },
        { text: 'Август', value: 8 },
        { text: 'Сентябрь', value: 9 },
        { text: 'Октябрь', value: 10 },
        { text: 'Ноябрь', value: 11 },
        { text: 'Декабрь', value: 12 },
    ]],

    ['SEASONAL_WORKER_END', [
        { text: '', value: null },
        { text: 'Январь', value: 1 },
        { text: 'Февраль', value: 2 },
        { text: 'Март', value: 3 },
        { text: 'Апрель', value: 4 },
        { text: 'Май', value: 5 },
        { text: 'Июнь', value: 6 },
        { text: 'Июль', value: 7 },
        { text: 'Август', value: 8 },
        { text: 'Сентябрь', value: 9 },
        { text: 'Октябрь', value: 10 },
        { text: 'Ноябрь', value: 11 },
        { text: 'Декабрь', value: 12 },
    ]],
]);
eadToValueListMap.forEach(values => { Object.freeze(values); });
Object.freeze(eadToValueListMap);
// endregion


// region Ключи форм отчетов
export const specificityToReportFormKeyMap: Map<number, Report.FormKey[]> = new Map([
    [111, ['F_01_111', 'F_02_111', 'F_04_111', 'F_05_111', 'F_06_111', 'F_07_111', 'F_08_111', 'F_09_111', 'F_10_111', 'F_11_111', 'F_13_111']],
    [112, ['F_01_112']],
    [113, ['F_01_113']],
    [114, ['F_02_114']],
    [116, ['F_01_116']],
    [121, ['F_01_121']],
    [122, ['F_01_122']],
    [124, ['F_01_124']],
    [131, ['F_01_131', 'F_02_131']],
    [132, ['F_01_132']],
    [135, ['F_01_135', 'F_03_135']],
]);
specificityToReportFormKeyMap.forEach(formKeys => { Object.freeze(formKeys); });
Object.freeze(specificityToReportFormKeyMap);

export const specificityCodes: number[] = ((): number[] => {
    const result: number[] = [];

    specificityToReportFormKeyMap.forEach((formKeys, specificityCode) => {
        result.push(specificityCode);
    });

    return result;
})();
specificityCodes.sort();
Object.freeze(specificityCodes);

export const reportFormKeys: Report.FormKey[] = ((): Report.FormKey[] => {
    const result: Report.FormKey[] = [];

    specificityCodes.forEach(specificityCode => {
        const keys = specificityToReportFormKeyMap.get(specificityCode);
        if (keys === undefined) {
            return;
        }
        result.push(...keys);
    });

    return result;
})();
Object.freeze(reportFormKeys);

export const reportFormKeyToSpecificityMap: Map<Report.FormKey, number> = ((): Map<Report.FormKey, number> => {
    const result = new Map<Report.FormKey, number>();

    specificityToReportFormKeyMap.forEach((reportFormKeys, specificity) => {
        reportFormKeys.forEach((reportFormKey) => {
            result.set(reportFormKey, specificity);
        });
    });

    return result;
})();
Object.freeze(reportFormKeyToSpecificityMap);

/** Формы, по которым считается зарплата */
export const salaryForms = reportFormKeys.filter(form =>
    (form.endsWith('111') || form.endsWith('131') || form.endsWith('132'))
);
// endregion


// region Работа со строками в NumberInput (modules/budget/staffing-table/NumberInput.vue)
/*
Примеры форматирования числа "-1234567890.09877" для разных региональных настроек (взято из LibreOffice Calc):
    русский (СНГ) - "-1 234 567 890,099"
    английский (UK / USA) - "-1,234,567,890.099"
 */


/**
 * Создает функцию форматирования числа
 *
 * @param locale Локаль для региональных настроек
 * @param minFractionalDigits Минимальное количество цифр дробной части
 * @param maxFractionalDigits Максимальное количество цифр дробной части
 *
 * @returns Функция форматирования
 */
export const createFormatter = (locale: string, minFractionalDigits?: number, maxFractionalDigits?: number): Utils.NumberInput.Formatter => {
    const preparedLocale = locale.trim().toLocaleLowerCase();

    const format = new Intl.NumberFormat(preparedLocale, {
        minimumFractionDigits: minFractionalDigits,
        maximumFractionDigits: maxFractionalDigits
    });

    return (value: number | null): string | null => {
        if ((value === null) || (!value.isFinite)) {
            return null;
        }
        return format.format(value);
    };
};

/**
 * Функция форматирования чисел для русского языка (региональные настройки соответствуют "Русский (Россия)")
 */
export const ruFormatter: Utils.NumberInput.Formatter = createFormatter('ru', 0, 15);

/**
 * Функция форматирования чисел для казахского языка (равно `ruFormatter`)
 */
export const kkFormatter: Utils.NumberInput.Formatter = ruFormatter;

/**
 * Функция форматирования чисел для английского языка (региональные настройки соответствуют "Английский (США)")
 */
export const enFormatter: Utils.NumberInput.Formatter = createFormatter('en', 0, 15);

/**
 * Функция форматирования чисел (простая резервная версия)
 */
export const fallbackFormatter: Utils.NumberInput.Formatter = (value) => (value ? String(value) : null);

/**
 * Карта "локаль -> функция форматирования чисел"
 */
export const localeToFormatterMap: Record<string, Utils.NumberInput.Formatter> = {
    'en': enFormatter,
    'kk': kkFormatter,
    'ru': ruFormatter
};
Object.freeze(localeToFormatterMap);


/**
 * Создает функцию чтения числа
 *
 * @param locale Локаль для региональных настроек
 *
 * @returns Функция чтения числа
 */
export const createParser = (locale: string): Utils.NumberInput.Parser => {
    // noinspection DuplicatedCode
    const preparedLocale = locale.trim().toLocaleLowerCase();

    const fractionalSeparator = ((): string => {
        let result = '.';
        if ((preparedLocale === 'kk') || (preparedLocale === 'ru')) {
            result = ',';
        }
        return result;
    })();

    return (value: string | null): number | null => {
        if ((value === null) || (value.length === 0)) {
            return null;
        }

        let numberStarted = false;
        let negative = false;
        let fractionalStarted = false;
        let preparedValue = '';

        let commaCount = 0;
        let dotCount = 0;
        for (const char of value) {
            switch (char) {
                case '.':
                    dotCount++;
                    break;
                case ',':
                    commaCount++;
                    break;
                default:
                    break;
            }
        }

        let usedFractionalSeparator = fractionalSeparator;
        if ((dotCount > 0) && (commaCount === 0)) {
            // только точки - похоже на 123456987.987 (дробь, программирование) или 123,456,987.987 (дробь, региональные настройки для английского языка)
            usedFractionalSeparator = '.';
        } else {
            // если только запятые - похоже на 123,456,987 (целое число, региональные настройки для английского языка) - лучше ничего не делать
        }

        for (const char of value) {
            switch (char) {
                case '-':
                    if ((!numberStarted) && (!negative)) {
                        negative = true;
                    }
                    break;
                case '0':
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                    if (!numberStarted) {
                        numberStarted = true;
                    }
                    preparedValue += char;
                    break;
                case usedFractionalSeparator:
                    if (!numberStarted) {
                        numberStarted = true;
                        preparedValue += '0.';
                    } else if (!fractionalStarted) {
                        fractionalStarted = true;
                        preparedValue += '.';
                    }
                    break;
                default:
                    // все остальные символы игнорируются
                    break;
            }
        }

        if (!numberStarted) {
            return null;
        }

        if (negative) {
            preparedValue = '-' + preparedValue;
        }

        if (preparedValue.endsWith('.')) {
            preparedValue += '0';
        }

        return parseFloat(preparedValue);
    };
};

/**
 * Функция чтения чисел для русского языка (региональные настройки соответствуют "Русский (Россия)")
 */
export const ruParser: Utils.NumberInput.Parser = createParser('ru');

/**
 * Функция чтения чисел для казахского языка (равно `ruParser`)
 */
export const kkParser: Utils.NumberInput.Parser = ruParser;

/**
 * Функция чтения чисел для английского языка (региональные настройки соответствуют "Английский (США)")
 */
export const enParser: Utils.NumberInput.Parser = createParser('en');

/**
 * Функция чтения чисел (простая резервная версия)
 */
export const fallbackParser: Utils.NumberInput.Parser = createParser('en');

/**
 * Карта "локаль -> функция чтения чисел"
 */
export const localeToParserMap: Record<string, Utils.NumberInput.Parser> = {
    'en': enParser,
    'kk': kkParser,
    'ru': ruParser
};
Object.freeze(localeToParserMap);

// noinspection SpellCheckingInspection
/*** Формат вывода даты "дд.мм.гггг" */
export const dateFormat = (locale: string): Intl.DateTimeFormat => {
    return new Intl.DateTimeFormat(locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
}

// noinspection SpellCheckingInspection
/*** Формат вывода даты "дд.мм.гггг чч:мм:сс"*/
export const dateTimeFormat = (locale: string): Intl.DateTimeFormat => {
    return new Intl.DateTimeFormat(locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });
}

export const updateFormattedDate = (date: Date): string =>  {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}.${month}.${day}`
}

/**
 * Настройки форматирования для компонента NumberInput по умолчанию
 */
export const defaultNumberInputFormats: Utils.NumberInput.Formats = {
    defaultFormatter: ruFormatter,
    getFormatter: (locale) => (localeToFormatterMap[locale.trim().toLocaleLowerCase()] || ruFormatter),
    localeToFormatterMap,

    defaultParser: ruParser,
    getParser: (locale) => (localeToParserMap[locale.trim().toLocaleLowerCase()] || ruFormatter),
    localeToParserMap
};
Object.freeze(defaultNumberInputFormats);

/**
 * Настройки форматирования для компонента NumberInput (простая резервная версия)
 */
export const fallbackNumberInputFormats: Utils.NumberInput.Formats = {
    defaultFormatter: fallbackFormatter,
    defaultParser: fallbackParser
};
Object.freeze(fallbackNumberInputFormats);


(() => {
    const debugFormatters = false;
    if (debugFormatters) {
        // eslint-disable-next-line no-console
        console.group('Formatters');
        try {
            const intParts = [0, 1, 12, 123, 1234, 12345, 123456, 1234567, 9, 99, 999, 9999, 99999];
            const floatParts = [0, 0.1, 0.9, 0.19, 0.99, 0.119, 0.999, 0.1119, 0.9999];

            for (const intPart of intParts) {
                // eslint-disable-next-line no-console
                console.groupCollapsed(intPart + '.*');
                try {
                    for (const floatPart of floatParts) {
                        // eslint-disable-next-line no-console
                        console.groupCollapsed(intPart + floatPart);
                        try {
                            const number = (intPart + floatPart);
                            const negative = -1 * number;

                            // eslint-disable-next-line no-console
                            console.log('en: [pos] ', enFormatter(number), ' => ', enParser(enFormatter(number)));
                            // eslint-disable-next-line no-console
                            console.log('kk: [pos] ', kkFormatter(number), ' => ', kkParser(kkFormatter(number)));
                            // eslint-disable-next-line no-console
                            console.log('ru: [pos] ', ruFormatter(number), ' => ', ruParser(ruFormatter(number)));

                            // eslint-disable-next-line no-console
                            console.log('en: [neg] ', enFormatter(negative), ' => ', enParser(enFormatter(negative)));
                            // eslint-disable-next-line no-console
                            console.log('kk: [neg] ', kkFormatter(negative), ' => ', kkParser(kkFormatter(negative)));
                            // eslint-disable-next-line no-console
                            console.log('ru: [neg] ', ruFormatter(negative), ' => ', ruParser(ruFormatter(negative)));
                        } finally {
                            // eslint-disable-next-line no-console
                            console.groupEnd();
                        }
                    }
                } finally {
                    // eslint-disable-next-line no-console
                    console.groupEnd();
                }
            }
        } finally {
            // eslint-disable-next-line no-console
            console.groupEnd();
        }
    }
})();
// endregion


// region Локальное хранилище
export const store: ModuleStore = ((): ModuleStore => {
    // region [data] Данные хранилища
    /**
     * Данные хранилища
     */
    interface IStoreData {
        /**
         * Признак "Хранилище инициализировано"
         */
        initialized: boolean;

        /**
         * АБП
         */
        abp: Dict.EbkFunc | null;

        /**
         * Организация
         */
        org: Org | null;

        /**
         * Версия ШР
         */
        version: Version | null;

        /**
         * Год отчета
         */
        reportYear: number;

        /**
         * Вариант бюджета
         */
        budgetVariant: BudgetVariants | null;

        /**
         * Бюджетная программа
         */
        budgetProgram: Dict.EbkFunc | null;

        /**
         * Форма
         */
        form: Report.FormKey | null;

        /**
         * Специфика
         */
        specificity: Dict.EbkEk | null;

        /**
         * Выбранный департамент
         */
        gaDepartmentList: Department[]

        /**
         * Дочерние департаменты, связанные с департаментом из gaDepartmentList
         */
        subDepartments: Department[]

        /**
         * Режим отображения сотрудников:
         *
         *  "STAFF_TAB" - отображение сотрудников с "обычными" должностями (не должностями в образовании);
         * "TARIFFICATION" - отображение сотрудников с должностями в образовании.
         */
        employeeMode: employeePositionMode | null

        /**
         * Ключ шаблона отчета
         */
        templateKey: TemplateKey | null;

        /**
         * Шаблон отчетов
         */
        conditionExp: BoolEx.Expression<boolean> | null;

        /**
         * Множественный выбор Бп
         */
        multiSelectedBudgetProgram: Dict.EbkFunc [];

        /**
         * Множественный выбор специфики
         */
        selectedSpecificOptions: Dict.EbkEk [];

        /**
         * Множественный выбор форм
         */
        selectedOptionsForms: string [];


    }

    const data = Vue.observable<IStoreData>({
        initialized: false,
        abp: null,
        org: null,
        version: null,
        reportYear: (new Date().getFullYear() + 1),
        budgetVariant: null,
        budgetProgram: null,
        form: null,
        specificity: null,
        gaDepartmentList: [],
        subDepartments: [],
        employeeMode: null,
        templateKey: null,
        conditionExp: null,
        multiSelectedBudgetProgram: [],
        selectedSpecificOptions: [],
        selectedOptionsForms: []
    });
    // endregion

    // region [localStorageAccess] Доступ к local storage
    type TLocalStorageKey = 'abp-code' | 'org-code' | 'version-id' | 'budget-variant-uuid' | 'budget-program-code' | 'year' | 'report-form' | 'report-form-specificity' | 'employee-mode' | 'template-key' | 'multi-budget-program' | 'specific-options' | 'options-forms' | 'condition-exp';

    const localStorageAccess = {
        localStorage: (localStorage as (Storage | undefined)),
        prefix: 'modules/budget/staffing-table/',

        useLocalStorage(usage: (storage: Storage) => void) {
            if (this.localStorage) {
                usage(this.localStorage);
            }
        },

        geFullKey(key: TLocalStorageKey): string { return `${this.prefix}${key}`; },

        readString(key: TLocalStorageKey): string | undefined {
            let result: string | null | undefined;
            this.useLocalStorage((storage) => {
                const fullKey = this.geFullKey(key);
                result = storage.getItem(fullKey);
            });
            return ((result === null) ? undefined : result);
        },

        writeString(key: TLocalStorageKey, value: string | undefined): void {
            this.useLocalStorage((storage) => {
                const fullKey = this.geFullKey(key);
                if (value === undefined) storage.removeItem(fullKey);
                else storage.setItem(fullKey, value);
            });
        },

        readInteger(key: TLocalStorageKey): number | undefined {
            const string = this.readString(key);
            if (string === undefined) return undefined;

            let result: number | undefined;
            try {
                result = Number.parseInt(string, 10);
            } catch (e) { /* ничего делать не надо */ }

            if ((result !== undefined) && Number.isInteger(result)) return result;
            else return undefined;
        },

        writeNumber(key: TLocalStorageKey, value: number | undefined): void {
            const string = ((value === undefined) ? undefined : String(value));
            this.writeString(key, string);
        },

        writeConditionExp(key: TLocalStorageKey, value: BoolEx.Expression<boolean> | undefined): void {
            this.useLocalStorage((storage) => {
                const fullKey = this.geFullKey(key);
                if (value === undefined) storage.removeItem(fullKey);
                const stringValue = JSON.stringify(value);
                storage.setItem(fullKey, stringValue);
            });
        },

        readConditionExp(key: TLocalStorageKey): BoolEx.Expression<boolean> | undefined {
            let result: string | null | undefined;
            this.useLocalStorage((storage) => {
                const fullKey = this.geFullKey(key);
                result = storage.getItem(fullKey);
            });
            if (result === null || result === undefined) {
                return undefined;
            } else {
                try {
                    return JSON.parse(result) as BoolEx.Expression<boolean>;
                } catch (e) {
                    console.error('Error parsing JSON from localStorage', e);
                    return undefined;
                }
            }
        }
    };
    // endregion

    // region Инициализация
    (() => {
        data.reportYear = (localStorageAccess.readInteger('year') || (new Date().getFullYear() + 1));
        data.templateKey = (localStorageAccess.readString('template-key') as TemplateKey) ?? null;
        data.conditionExp = (localStorageAccess.readConditionExp('condition-exp')) ?? null

        const reportForm = (localStorageAccess.readString('report-form') as (Report.FormKey | undefined));
        if ((reportForm !== undefined) && reportFormKeys.includes(reportForm)) data.form = reportForm;

        const urlParts: Array<string> = ['/api/budget/staffing_table/initialization-data'];
        const addUrlParam = (name: 'user-id' | 'abp-code' | 'org-code' | 'version-id' | 'budget-variant-uuid' | 'budget-program-code' | 'specificity-code', value: string | number | undefined): void => {
            if (value !== undefined) {
                if (urlParts.length === 1) urlParts.push('?');
                else urlParts.push('&');

                urlParts.push(name);
                urlParts.push('=');
                if (typeof value === 'string') urlParts.push(encodeURIComponent(value));
                else urlParts.push(encodeURIComponent(String(value)));
            }
        };

        addUrlParam('user-id', auth.user.sub);
        addUrlParam('abp-code', localStorageAccess.readInteger('abp-code'));
        addUrlParam('org-code', localStorageAccess.readString('org-code'));
        addUrlParam('version-id', localStorageAccess.readInteger('version-id'));
        addUrlParam('budget-variant-uuid', localStorageAccess.readString('budget-variant-uuid'));
        addUrlParam('budget-program-code', localStorageAccess.readInteger('budget-program-code'));
        addUrlParam('specificity-code', localStorageAccess.readInteger('report-form-specificity'));

        const url = urlParts.join('');

        Ax<{
            abp: Dict.EbkFunc | null;
            org: Org | null;
            version: Version | null;
            budgetVariant: BudgetVariants | null;
            budgetProgram: Dict.EbkFunc | null;
            specificity: Dict.EbkEk | null;
        }>(
            { url },
            (initData) => {
                data.abp = initData.abp;
                data.org = initData.org ?? null;
                data.version = initData.version ?? null;
                data.budgetVariant = initData.budgetVariant;
                data.budgetProgram = initData.budgetProgram;
                data.specificity = initData.specificity;
                if (data.form !== null) {
                    if ((initData.specificity === null) || (initData.specificity.spf === null)) data.form = null;
                    else {
                        const allowedFormKeys = specificityToReportFormKeyMap.get(initData.specificity.spf);
                        if ((allowedFormKeys === undefined) || allowedFormKeys.notIncludes(data.form)) data.form = null;
                    }
                }
            },
            (error) => { console.error('Cannot load initialization data for "staffing table" module', error.toString()); },
            () => { data.initialized = true; },
        )
    })();
    // endregion

    return {
        get initialized(): boolean { return data.initialized; },

        // region [abp] АБП
        get abp(): Dict.EbkFunc | null { return data.abp; },

        set abp(value: Dict.EbkFunc | null) {
            localStorageAccess.writeNumber('abp-code', (value?.abp || undefined));
            data.abp = value;
        },
        // endregion

        // region [org] Организация
        get org(): Org | null { return data.org; },

        set org(value: Org | null) {
            localStorageAccess.writeString('org-code', (value?.org?.code || undefined));
            data.org = value;
        },
        // endregion

        // region [version] Версия
        get version(): Version | null {
            return data.version;
        },

        set version(value: Version | null) {
            localStorageAccess.writeNumber('version-id', value?.id ?? undefined);
            data.version = value;
        },
        // endregion

        // region [reportYear] Год отчета / [reportDate] Дата отчета
        get reportYear(): number { return data.reportYear; },

        set reportYear(value: number) {
            localStorageAccess.writeNumber('year', value);
            data.reportYear = value;
        },

        get reportDate(): Date {
            const form = data.form;
            if (
                (form !== null)
                &&
                (form.endsWith('111') || form.endsWith('131') || form.endsWith('112') || form.endsWith('113'))
            ) {
                return new Date(data.reportYear, 0, 1);
            } else {
                return new Date(data.reportYear, 11, 31);
            }
        },
        // endregion

        // region [budgetVariant] Вариант бюджета
        get budgetVariant(): BudgetVariants | null { return data.budgetVariant; },

        set budgetVariant(value: BudgetVariants | null) {
            localStorageAccess.writeString('budget-variant-uuid', (value?.variantUuid || undefined));
            data.budgetVariant = value;
        },
        // endregion

        // region [budgetProgram] Бюджетная программа
        get budgetProgram(): Dict.EbkFunc | null { return data.budgetProgram; },

        set budgetProgram(value: Dict.EbkFunc | null) {
            localStorageAccess.writeNumber('budget-program-code', (value?.prg || undefined));
            data.budgetProgram = value;
        },
        // endregion

        // region [form] Форма
        get form(): Report.FormKey | null { return data.form; },

        set form(value: Report.FormKey | null) {
            localStorageAccess.writeString('report-form', (value || undefined));
            data.form = value;
        },
        // endregion

        // region [specificity] Специфика
        get specificity(): Dict.EbkEk | null { return data.specificity; },

        set specificity(value: Dict.EbkEk | null) {
            localStorageAccess.writeNumber('report-form-specificity', (value?.spf || undefined));
            data.specificity = value;
        },
        // endregion

        get employeeMode(): employeePositionMode | null { return data.employeeMode; },

        set employeeMode(value: employeePositionMode | null) {
            localStorageAccess.writeString('employee-mode', (value || undefined));
            data.employeeMode = value;
        },

        get templateKey(): TemplateKey | null { return data.templateKey; },

        set templateKey(value: TemplateKey | null) {
            localStorageAccess.writeString('template-key', (value || undefined));
            data.templateKey = value;
        },

        get conditionExp(): BoolEx.Expression<boolean> | null { return data.conditionExp; },

        set conditionExp(value: BoolEx.Expression<boolean> | null) {
            localStorageAccess.writeConditionExp('condition-exp', (value || undefined));
            data.conditionExp = value;
        },

        get multiSelectedBudgetProgram(): Dict.EbkFunc [] { return data.multiSelectedBudgetProgram; },

        set multiSelectedBudgetProgram(value: Dict.EbkFunc []) {
            data.multiSelectedBudgetProgram = value;
        },

        get selectedSpecificOptions(): Dict.EbkEk [] { return data.selectedSpecificOptions; },

        set selectedSpecificOptions(value: Dict.EbkEk []) {
            data.selectedSpecificOptions = value;
        },

        get selectedOptionsForms(): string [] { return data.selectedOptionsForms; },

        set selectedOptionsForms(value: string []) {
            data.selectedOptionsForms = value;
        },

        get gaDepartmentList(): Department[] {
            return data.gaDepartmentList || [];
        },
        set gaDepartmentList(value: Department[]) {
            data.gaDepartmentList = value;
        },

        get subDepartments(): Department[] {
            return data.subDepartments || [];
        },
        set subDepartments(value: Department[]) {
            data.subDepartments = value;
        },

    };
})();
// endregion